import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {Alert, message, InputNumber} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {MoneyDollarCircle} from '@styled-icons/remix-line/MoneyDollarCircle';
import SelectCompanyWidget from '../../Components/SelectCompanyWidget';
import {
  AdminAddressCityWidget,
  AdminAddressZipCodeWidget,
} from '../../Generators/AdminResource/AdminAddressWidget';
import userSuccessJson from '../../../content/official/user-success.json';
import userPendingJson from '../../../content/official/user-pending.json';
import userExtraJson from '../../../content/official/user-extra.json';
import * as AppActions from '../../AppActions';

function ProfilePage(props) {
  const [user, setUser] = useOutlet('user');
  const [schoolCategoryDisplayMap] = useOutlet('schoolCategoryDisplayMap');
  const [expertiseCategoryDisplayMap] = useOutlet(
    'expertiseCategoryDisplayMap',
  );
  const [associationCategoryDisplayMap] = useOutlet(
    'associationCategoryDisplayMap',
  );

  const [studyHoursRecords, setStudyHoursRecords] = React.useState([]);

  const user_type = user?.data?.user_type || 'member';

  const isShowUprWarning =
    user &&
    user.data &&
    (!user.data.name || !user.data.phone || !user.data.email);

  const schema =
    user_type === 'extra_member'
      ? userExtraJson.schema
      : user.data?.status === 'success' || user.data?.status === 'withdraw'
      ? userSuccessJson.schema
      : userPendingJson.schema;

  const uiSchema =
    user_type === 'extra_member'
      ? userExtraJson.uiSchema
      : user.data?.status === 'success' || user.data?.status === 'withdraw'
      ? userSuccessJson.uiSchema
      : userPendingJson.uiSchema;

  const errorSchema =
    user_type === 'extra_member'
      ? userExtraJson.errorSchema
      : user.data?.status === 'success' || user.data?.status === 'withdraw'
      ? userSuccessJson.errorSchema
      : userPendingJson.errorSchema;

  function handleCustomEnum(schema) {
    if (
      schema.properties.expertises &&
      schema.properties.expertises.items.properties.name.enum ===
        'expertise_category'
    ) {
      schema.properties.expertises.items.properties.name.enum = [
        '',
        ...Object.keys(expertiseCategoryDisplayMap),
      ];
      schema.properties.expertises.items.properties.name.enumNames = [
        '無',
        ...Object.values(expertiseCategoryDisplayMap),
      ];
    }

    if (
      schema.properties.associations &&
      schema.properties.associations.items.properties.BAR.enum ===
        'association_category'
    ) {
      schema.properties.associations.items.properties.BAR.enum = Object.keys(
        associationCategoryDisplayMap,
      );
      schema.properties.associations.items.properties.BAR.enumNames = Object.values(
        associationCategoryDisplayMap,
      );
    }

    if (schema.properties.educations) {
      schema.properties.educations.items.properties.name.enum = Object.keys(
        schoolCategoryDisplayMap,
      );
    }
  }

  handleCustomEnum(schema);

  const widgets = {
    'select-company-widget': SelectCompanyWidget,
    'admin-address-city-widget': AdminAddressCityWidget,
    'admin-address-zip-code-widget': AdminAddressZipCodeWidget,
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const {total, results} = await JStorage.fetchDocuments(
          'study_hours',
          {
            user_id: user.sub,
            first_year: {
              $in: [
                new Date().getFullYear() - 1911,
                new Date().getFullYear() - 1912,
              ],
            },
          },
          ['-created'],
          {offset: 0, limit: 2},
        );

        setStudyHoursRecords(results);
      } catch (err) {
        console.warn(err);
      }
    }

    fetchData();
  }, [user?.sub]);

  return (
    <Wrapper>
      <h2 className="title">個人資訊</h2>
      <div className="container">
        {isShowUprWarning && (
          <Alert
            showIcon
            type="warning"
            message="新系統上線，請填寫姓名、EMAIL 與手機，以確保您報名課程活動之權益。"
            style={{marginTop: 20, marginBottom: 20}}
          />
        )}

        <FieldWrapper row>
          <label>
            <MoneyDollarCircle
              color="#FFD306"
              size={20}
              style={{marginRight: 5}}
            />
            本年度免費時數
          </label>
          <InputNumber
            className="input-number"
            value={user.data?.points}
            addonAfter="小時"
            readOnly={true}
          />
        </FieldWrapper>

        {studyHoursRecords.map((record, index) => {
          const isLastYear =
            record.first_year === new Date().getFullYear() - 1912;

          return (
            <FieldWrapper key={index} row>
              <label>
                <MoneyDollarCircle
                  color="#FFD306"
                  size={20}
                  style={{marginRight: 5}}
                />
                {isLastYear ? '去年度' : '今年度'}進修時數
              </label>
              <InputNumber
                addonBefore={<span>{record.first_year}年度</span>}
                className="input-number"
                value={studyHoursRecords[index].hours}
                addonAfter="小時"
                readOnly={true}
              />
            </FieldWrapper>
          );
        })}

        <GenericForm
          rjsfProps={{widgets}}
          instance={user.data}
          schema={schema}
          uiSchema={uiSchema}
          errorSchema={errorSchema}
          onSubmit={async (formData, extValues) => {
            console.log('formData', formData);

            if (user_type === 'member' && !formData.company_current) {
              message.warn('請填寫現在事務所資料');
              AppActions.setLoading(false);
              return;
            }

            //FIXME: AdminAddressWidget should support required detection
            if (
              user_type === 'member' &&
              !formData.residence_address_zip_code
            ) {
              message.warn('請填寫戶籍地址-郵遞區號');
              AppActions.setLoading(false);
              return;
            }

            try {
              AppActions.setLoading(true);
              const resp = await JStorage.updateDocument(
                'user_profile',
                {id: user.data.id},
                formData,
              );

              setUser({...user, data: {...user.data, ...resp}});
              AppActions.setLoading(false);

              message.success('儲存成功。');
            } catch (err) {
              console.warn(err);
              message.error('發生錯誤，請稍後再試。');
              AppActions.setLoading(false);
            }
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;

  max-width: var(--containerMaxWidth990px);
  width: 100%;

  & > .title {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  & > .container {
    margin: 0 auto;
  }

  & .description {
    border-radius: 10px;
    background-color: #f0f0f0;
    padding: 20px;

    max-width: 800px;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 1rem;
    line-height: 1.85rem;

    & > b {
      margin-right: 5px;
      margin-left: 5px;
      color: var(--secondColor);
    }

    & > a {
      margin-right: 5px;
      margin-left: 5px;
      padding-bottom: 2px;
      border-bottom: 1px solid var(--primaryColor);
    }
  }
`;

const FieldWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: ${(props) => props.row || 'column'};
  align-items: center;
  flex-wrap: wrap;

  & > label {
    min-width: 120px;
    line-height: 40px;
    ${(props) => (props.row ? 'padding-right: 20px;' : 'padding-bottom: 8px;')}
  }

  & > .input-number {
    width: 250px;
    padding-right: 20px;
  }

  & > .input,
  .select {
    flex: 1;
  }

  ${(props) => props.css || ''};
`;

export default ProfilePage;
