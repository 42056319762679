import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {showSearchCompanyModal} from '../SearchCompanyModal';

export default function SelectCompanyWidget(props) {
  const {schema} = props;
  const [user] = useOutlet('user');
  const [companies] = useOutlet('companies');
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  const {data} = user || {};

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;
  const displayCompanyDataOnly = schema.displayCompanyDataOnly || false;

  React.useEffect(() => {
    if (props.value) {
      setSelectedCompany(companies.find((c) => c.id === props.value));
    }
  }, [props.value, companies]);

  return (
    <Wrapper>
      <div className="info">
        {!props.value ? (
          '尚未設定事務所'
        ) : !selectedCompany ? (
          props.value
        ) : (
          <div>
            <h3 className="title">{selectedCompany.CONAME}</h3>
            {displayCompanyDataOnly && (
              <>
                <div>
                  地址：{selectedCompany.address_zip_code || ''}
                  {selectedCompany.address_city || ''}
                  {selectedCompany.address_detail || ''}
                </div>
                <div>電話：{selectedCompany.COPHONE1 || ''}</div>
                <div>傳真：{selectedCompany.COFAX1 || ''}</div>
              </>
            )}

            {!displayCompanyDataOnly && (
              <>
                <div>
                  地址：
                  {data?.company_zip_code ||
                    selectedCompany?.address_zip_code ||
                    '無'}{' '}
                  {data?.company_city || selectedCompany?.address_city || '無'}
                  {data?.company_detail ||
                    selectedCompany?.address_detail ||
                    '無'}
                </div>
                <div>
                  電話：
                  {data?.company_phone || selectedCompany?.COPHONE1 || '無'}
                </div>
                <div>
                  傳真：
                  {data?.company_fax || selectedCompany?.COFAX1 || '無'}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {!readonly && (
        <div>
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedCompany(null);
            }}
            style={{marginRight: 10}}>
            清除
          </Button>
          <Button
            type="primary"
            onClick={() =>
              showSearchCompanyModal({
                onChange: (id) => {
                  props.onChange(id);
                },
              })
            }>
            搜尋
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  & > .info {
    margin-right: 15px;

    & .title {
      margin-bottom: 6px;
      color: var(--secondColor);
    }
  }
`;
